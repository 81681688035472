<template>
  <div class="manage-page">
    <router-view v-if="account"></router-view>
    <div v-else class="w-full h-full flex items-center justify-center">
      <ConnectWalletButton />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConnectWalletButton from "../components/ConnectWalletButton.vue";
export default {
  components: { ConnectWalletButton },
  computed: {
    ...mapGetters({
      account: "wallet/account",
    }),
  },
  metaInfo: {
    title: "Project List - Zaif INO ",
  },
};
</script>

<style lang="scss">
.manage-page {
  @apply mt-10 lg:mt-18 px-6;
  @screen xl {
    max-width: 1140px;
    @apply mx-auto px-0;
  }
}
</style>
